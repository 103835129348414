import React, { useContext } from "react";
import GlobalContext from "../../contexts/global-provider";

export default () => {
  const { theme } = useContext(GlobalContext);
  return (
    <section className="partners">
      <img className="partners__item" src={`/${theme}/png/selectair_logo.png`} alt="Selectair" />
      <img className="partners__item" src={`/${theme}/png/gfg_logo.png`} alt="GFG" />
    </section>
  );
};
